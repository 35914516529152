
.navbar {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
  z-index: 100; 
}

.titel{
  font-size:20px;
  text-decoration:none;
  color: rgb(18, 16, 16);
  
  
}
.nav-logo {
  gap: 10px;
  align-items: center;
  display: flex;
}

.nav-menu {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 50px;
  font-size: 20px;


}

.nav-menu li{
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;

}

.nav-menu a {
  text-decoration: none;
  color: #000;
 
}

.nav-menu a:hover {
  color: #770737;
}


.nav-social-media {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

.nav-social-media a {
  margin-right: 1rem;
  font-size: 1.5rem;
  color: #000;
}

@media only screen and (max-width: 600px) {
  
  .logo{
    padding: 20px;
    margin-left: -1rem;
  }
  .navbar{
    padding: 0;
    position: absolute;
    left: -0.4rem;  
  }

  .titel{
    font-size:14px;
    text-decoration:none;
    color: rgb(18, 16, 16);
    
    
  }
  .nav-logo {
    gap: 0;
    
  }
  
  .nav-menu {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 50px;
    font-size: 15px;
  
  
  }
  
  .nav-menu li{
    margin-left:0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0;
  
  }
  
  .nav-menu a {
    text-decoration: none;
    color: #000;
   
  }
  
  .nav-menu a:hover {
    color: #770737;
  }
  
 


}