body {
 
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-image: url('./assets/Image/bcg.jpg');
  background-position: 10;
  background-repeat: no-repeat;
background-size: cover;
white-space: nowrap;
overflow: hidden;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@media only screen and (max-width: 600px) {
  body {
  background-image: url("./assets/Image/mb.jpeg");
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  background-size: 390px;
  }
}