

.bg-Image{
    bottom: -10px;
    width: 100%;
  height: 100vh;
    object-fit: fill;
}

.content{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 20px;
    display: flex;
    flex-direction: column;
     justify-content: center;
     align-items: center;
     color: #770737;
     text-decoration: none;
     margin-bottom: -20px;
    
}
.hero-h1{

    color: #770737;
    margin-right:30px ;
    font-size: 50px;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.hero-p{
    margin-right: 40px;
    font-weight: bolder;
    font-size: 35px;
}
.hero-ul{
    list-style-type: none;
    position: absolute;
   right: 20px;
  

} 


.social-icon{
    font-size: 40px;
    text-decoration: none;
    color:black;
    margin-left: -30px;

}
.social-icon:hover{
    color:#770737;
   
}

.overlay{
 position: absolute;
 top: 0;
 left: 0;
 width: 100%;
 height:90%;

} 

@media only screen and (max-width: 600px) {

    .bg-Image{
        bottom: -10px;
        width: 90%;
        
      height: 100vh;
        object-fit: fill;
    }
    
    .content{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0px;
        display: flex;
        flex-direction: column;
         justify-content: center;
         align-items: center;
         color: #770737;
         text-decoration: none;
         margin-bottom: -20px;
        
    }
    .hero-h1{
    
       margin-top: -10rem;
        margin-right:10px ;
        font-size: 25px;
        
    }
    .hero-p{
        margin-top: -1rem;
        margin-right: 30px;
        font-weight: bolder;
        font-size: 23px;
    }
    .hero-ul{
        list-style-type: none;
        position: absolute;
       right: 5px;
      
    
    } 
    
    
    .social-icon{
        font-size: 30px;
        text-decoration: none;
        color:black;
        margin-left: -30px;
    
    }
    .social-icon:hover{
        color:#770737;
       
    }
    
    .overlay{
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height:90%;
    
    } 
}