

.ab-content{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 20px;
    display: flex;
    flex-direction: column;
     justify-content: center;
     align-items: center;
     color: #770737;
    margin-left: 200px;
  bottom: -220px;
}
.cont-h1{
    margin-left: 50px;
    padding-left: 30px;
}
input[type=text]{
    margin-left: 20px;
    margin-bottom: 20px;
    padding-top: 10px;
   border: none;
   outline-color: #770737;
   border-radius: 7px;
   width: 100%;
   padding: 12px 20px;
}
.contact{
    display: flex;
    flex-direction: column;
}
textarea{
    margin-left: 20px;
    margin-bottom: 20px;
    resize: none;
    outline-color: #770737;
    width: 100%;
    border-radius: 7px;
    padding: 12px 20px;
    border: none;
}
::placeholder {
    color: #770737;
    opacity: 1;
    font-weight: bold;
  }
  
  ::-ms-input-placeholder { 
    color: #770737;
  }
.btn{
    margin-left: 5rem;
    padding-right: 5rem;
    padding-right: 20px;
     padding: 10px 0 10px 3px;
    font-size: 20px;
  border-radius: 10px;
    background-color: #770737;
    color: white;
    cursor: pointer;
    border: none;
}
.btn:hover{
    background-color: #b13c6f;
}


@media only screen and (max-width: 600px) {



  .ab-content{
  
    width: 10px;
    height: 100%;
  padding-left: -5rem;
    
   
}
.cont-h1{
    margin-left: -4rem;
    padding-left: 30px;
}
input[type=text]{
    margin-left: -4rem;
   padding: 20px 100px 10px 10px; 
}

textarea{
    margin-left: -4rem;
    padding: 20px 100px 10px 10px; 
}

.btn{
    margin-left: 4rem;
    padding-right: 5rem;
    padding-right: 20px;
     padding: 10px  10px 10px 3px;
    font-size: 30px;

}


}